<template>
  <div style="margin:50px auto;display:block;">
      <p style="padding:25px 0px 25px 0px;font-size:30px;color:#333;font-weight:600;text-align:center">
          TeamGlobal
      </p>
      <div style="margin:50px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>

      <div style="margin:0 auto;display:block;width:90%;">
          <v-layout row justify-center>
          <v-flex xs12 md3 v-for="(item, i) in Juntas" :key="i" class="my-2">

              <v-card :elevation="6"
              flat style="width:90%;border:solid 1px #ccc;border-radius:10px;">
                  <v-img :src="item.img_profile" style="margin:0 auto;display:block;"
                  height="500"
                  ></v-img>
                  <v-card-text>
                      <p class="text-center title">
                          {{item.username}}
                      </p>
                      <div class="text-center pb-2" style="font-weight:600;color:#D41700;font-style:italic;"
                      v-if="item.id == 1">
                          Director General & Asesor Inmobiliario
                      <div @click="irInmuebles({asesor: item.uid})"
                      small style="cursor:pointer;
                      margin:0 auto;display:block;text-align:center;padding:5px 0px 5px 0px;
                      border-radius:5px;
                      background:#00174A;color:white;text-decoration:none;">
                          Ver Inmuebles
                      </div>
                      </div>
                      <div class="text-center pb-2" style="font-weight:600;color:#D41700;font-style:italic;"
                      v-if="item.id == 2">
                          Gerente Administrativo & Asesor Inmobiliario
                          <div @click="irInmuebles({asesor: item.uid})"
                      small style="cursor:pointer;
                      margin:0 auto;display:block;text-align:center;padding:5px 0px 5px 0px;
                      border-radius:5px;
                      background:#00174A;color:white;text-decoration:none;">
                          Ver Inmuebles
                      </div>
                      </div>
                      <div class="text-center pb-2" style="font-weight:600;color:#D41700;font-style:italic;"
                      v-if="item.id == 3">
                          Gerente Legal & Coaching
                      </div>
                      <div class="text-center pb-2" style="font-weight:600;color:#D41700;font-style:italic;"
                      v-if="item.id == 4">
                          Gerente de Marketing & RRSS
                      </div>
                      <p class="subtitle text-center"><strong>
                          <i class="fas fa-info-circle pl-2"></i> {{item.oficina}}
                          </strong></p>
                          <p>
                            <a :href="`mailto:${item.email}`" style="text-decoration:none;color:#333;font-size:10px;">
                                <i class="fas fa-mail-bulk"></i> {{item.email}}</a> <br><br>



                            <a :href="`https://api.whatsapp.com/send?phone=${item.tlf}&text=%20%20`" 
                            style="text-decoration:none;color:#333;">
                                <i class="fab fa-whatsapp pt-2"></i> {{item.tlf}} <br><br></a>


                            <a target="_blank" :href="`https://instagram.com/${item.tiktok}`"
                             style="text-decoration:none;color:#333"
                            >
                                <v-img style="float:left" class="mt-1"
                                src="https://iconape.com/wp-content/files/nk/19010/png/19010.png" 
                                height="12" width="12"></v-img> <span class="px-2">TikTok</span>
                            </a><br><br>

                            
                            <a target="_blank" :href="`https://instagram.com/${item.instagram}`"
                             style="text-decoration:none;color:#333"
                            >
                                <i class="fab fa-instagram pr-2"></i> Instagram
                            </a>
                          </p>
                  </v-card-text>
              </v-card>

          </v-flex>
      </v-layout>
      </div>


      <p style="padding:50px 0px 25px 0px;font-size:30px;color:#333;font-weight:600;text-align:center">
          Asesores Inmobiliarios
      </p>
      <div style="margin:50px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>

      <div style="margin:0 auto;display:block;width:90%;">
          <v-layout row justify-center>
          <v-flex xs12 md3 v-for="(item, i) in DatosDelAsesor" :key="i" class="my-2">

              <v-card :elevation="6"
              flat style="width:90%;border:solid 1px #ccc;border-radius:10px;" 
              v-if="item.id != 1 && item.id != 2 && item.id != 3 && item.id != 4">
                  <v-img :src="item.img_profile" style="margin:0 auto;display:block;"
                  height="400"></v-img>
                  <v-card-text>
                      <p class="text-center title">
                          {{item.username}}
                      </p>
                      <div class="text-center pb-2" style="font-weight:600;color:#D41700;font-style:italic;">
                          Asesor Inmobiliario
                      </div>
                      <p class="subtitle text-center"><strong>
                          <i class="fas fa-info-circle pl-2"></i> {{item.oficina}}
                          </strong></p>
                          <p>
                            <a :href="`mailto:${item.email}`" style="text-align:center;text-decoration:none;color:#333;font-size:10px;">
                                <i class="fas fa-mail-bulk text-center"></i> <strong>{{item.email}}</strong></a> <br><br>



                            <a :href="`https://api.whatsapp.com/send?phone=${item.tlf}&text=%20%20`" 
                            style="text-decoration:none;color:#333;">
                                <i class="fab fa-whatsapp pt-2"></i> {{item.tlf}} <br><br></a>


                            <a target="_blank" :href="`https://instagram.com/${item.tiktok}`"
                             style="text-decoration:none;color:#333"
                            >
                                <v-img style="float:left" class="mt-1"
                                src="https://iconape.com/wp-content/files/nk/19010/png/19010.png" 
                                height="12" width="12"></v-img> <span class="px-2">TikTok</span>
                            </a><br><br>

                            
                            <a target="_blank" :href="`https://instagram.com/${item.instagram}`"
                             style="text-decoration:none;color:#333"
                            >
                                <i class="fab fa-instagram pr-2"></i> Instagram
                            </a>
                          </p>
                  </v-card-text>
                  <v-card-text>
                      <div @click="irInmuebles({asesor: item.uid})"
                      small style="cursor:pointer;
                      margin:0 auto;display:block;text-align:center;padding:5px 0px 5px 0px;
                      border-radius:5px;
                      background:#00174A;color:white;text-decoration:none;">
                          Ver Inmuebles
                      </div>
                  </v-card-text>
              </v-card>

          </v-flex>
      </v-layout>
      </div>
      
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            DatosDelAsesor:[],Juntas:[]
        }
    },
    methods:{
        irInmuebles(e){
            const _ASESOR = e.asesor;
            const _URL = "/Asesor?uid="+_ASESOR;
            window.open(_URL, "_self")
        },
        loadAsesorResponsable(){
            const URL_INMOGLOBAL_VE = 'https://inmoglobalve.com.ve/php/LoadAsesores.php';
            axios.post(URL_INMOGLOBAL_VE).then(res=>{
                this.DatosDelAsesor = res.data
            })
        },
        JuntasDirecitiva(){
            const URL_INMOGLOBAL_VE = 'https://inmoglobalve.com.ve/php/LoadJuntaDirect.php';
            axios.post(URL_INMOGLOBAL_VE).then(res=>{
                this.Juntas = res.data
            })
        }
    },
    mounted(){
        this.loadAsesorResponsable(),this.JuntasDirecitiva()
    }
}
</script>

<style>

</style>